import { Router } from "./manager.js";
import * as routerLink from "./router-link.js";
import * as routerOutlet from "./router-outlet.js";
import type * as types from "./types.js";

export * from "./manager.js";

export const bootstrap = async ({ routes, useShadowDom }: types.ConfigType) => {
	console.log("[app-router]: bootstrap", routes);

	Router.init(routes);

	routerOutlet.RouterOutlet.register(useShadowDom);
	routerLink.RouterLink.register();
};

export const mount = async (element: HTMLElement) => {
	console.log("[app-router]: mount");

	const routerElem = routerOutlet.RouterOutlet.createElement();
	element.appendChild(routerElem);
	console.log("[app-router]: mounted ", routerElem);

	await Router.load();
};
