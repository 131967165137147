import { Router } from "@capitec/omni-router";

const TagName = "router-link";

export class RouterLink extends HTMLAnchorElement {
	public state: string | undefined = undefined;
	// onClick;

	constructor() {
		super();
		console.log("RouterLink");
		// this.onClick = this._onClick.bind(this);
	}

	onClick(ev: MouseEvent) {
		ev.preventDefault();
		Router.push(this.getAttribute("href") || "/", this.state);
	}

	connectedCallback() {
		this.addEventListener("click", this.onClick);
	}

	disconnectedCallback() {
		this.removeEventListener("click", this.onClick);
	}

	static register = () => {
		customElements.define(TagName.toString(), RouterLink, { extends: "a" });
	};

	static createElement = () => {
		return document.createElement(TagName.toString());
	};
}

declare global {
	interface HTMLElementTagNameMap {
		"router-link": RouterLink;
	}
}
