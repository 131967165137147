import {
	type Route,
	type RoutedLocation,
	Router as RouterImpl,
} from "@capitec/omni-router";
export type { Route, RoutedLocation } from "@capitec/omni-router";

import { BehaviorSubject } from "rxjs";

const currentLocation$ = new BehaviorSubject<RoutedLocation | undefined>(
	undefined,
);
const init = (routes: Route[]) => {
	routes.forEach((route: Route, index: number) => {
		RouterImpl.addRoute({
			...route,
		});
	});

	RouterImpl.addEventListener("route-loaded", (e) => {
		console.log("route-loaded", e);
		currentLocation$.next(e.current);
	});
	RouterImpl.addEventListener("route-loading", (e) => {
		console.log("route-loading", e);
	});
};

RouterImpl.push;

export const Router = {
	init,
	load: () => RouterImpl.load(),
	currentLocation$,
	get currentLocation(): RoutedLocation | undefined {
		return RouterImpl.currentLocation;
	},
	push: (path: string, state = {}, animateOut = false) =>
		RouterImpl.push(path, state, animateOut),
	replace: (path: string, state = {}, animateOut = false) =>
		RouterImpl.replace(path, state, animateOut),
	pop: (delta?: number) => RouterImpl.pop(delta),
	popToPath: (path: string, before = false) =>
		RouterImpl.popToPath(path, before),
};
